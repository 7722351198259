import { graphql } from "gatsby"

// Advanced Custom Field Blocka
import { AcfBackgroundContainerBlockPreview } from "~/components/blocks/Acf/AcfBackgroundContainerBlock/queries"
import { AcfBackgroundImageContainerBlockPreview } from "~/components/blocks/Acf/AcfBackgroundImageContainerBlock/queries"
import { AcfBannerBlockPreview } from "~/components/blocks/Acf/AcfBannerBlock/queries"
import { AcfBrewGalleryBlockPreview } from "~/components/blocks/Acf/AcfBrewGalleryBlock/queries"
import { AcfButtonBlockPreview } from "~/components/blocks/Acf/AcfButtonBlock/queries"
import { AcfCalloutCtaBlockPreview } from "~/components/blocks/Acf/AcfCalloutCtaBlock/queries"
import { AcfCollapseColumnsBlockPreview } from "~/components/blocks/Acf/AcfCollapseColumnsBlock/queries"
import { AcfFiftyFiftyBlockPreview } from "~/components/blocks/Acf/AcfFiftyFiftyBlock/queries"
import { AcfHtmlBlockPreview } from "~/components/blocks/Acf/AcfHtmlBlock/queries"
import { AcfIntroBlockPreview } from "~/components/blocks/Acf/AcfIntroBlock/queries"
import { AcfLogoSliderBlockPreview } from "~/components/blocks/Acf/AcfLogoSliderBlock/queries"
import { AcfMemberBenefitBlockPreview } from "~/components/blocks/Acf/AcfMemberBenefitBlock/queries"
import { AcfPostBannerBlockPreview } from "~/components/blocks/Acf/AcfPostBannerBlock/queries"
import { AcfQuickColumnsBlockPreview } from "~/components/blocks/Acf/AcfQuickColumnsBlock/queries"
import { AcfSlashSpacerBlockPreview } from "~/components/blocks/Acf/AcfSlashSpacerBlock/queries"
import { AcfTestimonialWithPhotoBlockPreview } from "~/components/blocks/Acf/AcfTestimonialWithPhotoBlock/queries"
import { AcfTimedContainerBlockPreview } from "~/components/blocks/Acf/AcfTimedContainerBlock/queries"
import { AcfTwinCardBlockPreview } from "~/components/blocks/Acf/AcfTwinCardBlock/queries"
import { AcfVideoBlockPreview } from "~/components/blocks/Acf/AcfVideoBlock/queries"

// Brew Block Library Blocks
import { BblButtonGroupBlockPreview } from "~/components/blocks/Bbl/BblButtonGroupBlock"
import { BblCardBlockPreview } from "~/components/blocks/Bbl/BblCardBlock/queries"
import { BblLatestNewsSliderBlockPreview } from "~/components/blocks/Bbl/BblLatestNewsSliderBlock/queries"
import { BblMemberBenefitCardBlockPreview } from "~/components/blocks/Bbl/BblMemberBenefitCardBlock/queries"
import { BblPageTitleBlockPreview } from "~/components/blocks/Bbl/BblPageTitleBlock/queries"
import { BblPostWrapBlockPreview } from "~/components/blocks/Bbl/BblPostWrapBlock/queries"
import { BblTestimonialSliderBlockPreview } from "~/components/blocks/Bbl/BblTestimonialSliderBlock/queries"
import { BblThinIntroBlockPreview } from "~/components/blocks/Bbl/BblThinIntroBlock/queries"
import { BblServiceListingBlockPreview } from "~/components/blocks/Bbl/BblServiceListingBlock/queries"

// Core Blocks
import { CoreHeadingBlockPreview } from "~/components/blocks/CoreHeadingBlock"
import { CoreImageBlockPreview } from "~/components/blocks/CoreImageBlock"
import { CoreListBlockPreview } from "~/components/blocks/CoreListBlock"
import { CoreParagraphBlockPreview } from "~/components/blocks/CoreParagraphBlock"
import { CoreTableBlockPreview } from "~/components/blocks/CoreTableBlock"
import { CoreSpacerBlockPreview } from "~/components/blocks/CoreSpacerBlock"

export const CoreFields = graphql`
  fragment CoreFields on WpBlock {
    name
    ...AcfBackgroundContainerBlock
    ...AcfBackgroundImageContainerBlock
    ...AcfBannerBlock
    ...AcfBrewGalleryBlock
    ...AcfButtonBlock
    ...AcfCalloutCtaBlock
    ...AcfCollapseColumnsBlock
    ...AcfFiftyFiftyBlock
    ...AcfHtmlBlock
    ...AcfIntroBlock
    ...AcfLogoSliderBlock
    ...AcfMemberBenefitBlock
    ...AcfPostBannerBlock
    ...AcfQuickColumnsBlock
    ...AcfSlashSpacerBlock
    ...AcfTestimonialWithPhotoBlock
    ...AcfTimedContainerBlock
    ...AcfTwinCardBlock
    ...AcfVideoBlock
    ...BblButtonGroupBlock
    ...BblCardBlock
    ...BblLatestNewsSliderBlock
    ...BblMemberBenefitCardBlock
    ...BblPageTitleBlock
    ...BblPostWrapBlock
    ...BblTestimonialSliderBlock
    ...BblThinIntroBlock
    ...BblServiceListingBlock
    ...CoreHeadingBlock
    ...CoreImageBlock
    ...CoreListBlock
    ...CoreParagraphBlock
    ...CoreTableBlock
    ...CoreSpacerBlock
  }
`

export const PreviewBlocks = `
  ${AcfBackgroundContainerBlockPreview}
  ${AcfBackgroundImageContainerBlockPreview}
  ${AcfBannerBlockPreview}
  ${AcfBrewGalleryBlockPreview}
  ${AcfButtonBlockPreview}
  ${AcfCalloutCtaBlockPreview}
  ${AcfCollapseColumnsBlockPreview}
  ${AcfFiftyFiftyBlockPreview}
  ${AcfHtmlBlockPreview}
  ${AcfIntroBlockPreview}
  ${AcfLogoSliderBlockPreview}
  ${AcfMemberBenefitBlockPreview}
  ${AcfPostBannerBlockPreview}
  ${AcfQuickColumnsBlockPreview}
  ${AcfSlashSpacerBlockPreview}
  ${AcfTestimonialWithPhotoBlockPreview}
  
  ${AcfTimedContainerBlockPreview}
  ${AcfTwinCardBlockPreview}
  ${AcfVideoBlockPreview}
  
  ${BblButtonGroupBlockPreview}
  ${BblCardBlockPreview}
  ${BblLatestNewsSliderBlockPreview}
  ${BblMemberBenefitCardBlockPreview}
  ${BblPageTitleBlockPreview}
  ${BblPostWrapBlockPreview}
  ${BblTestimonialSliderBlockPreview}
  ${BblThinIntroBlockPreview}
  ${BblServiceListingBlockPreview}

  ${CoreHeadingBlockPreview}
  ${CoreImageBlockPreview}
  ${CoreListBlockPreview}
  ${CoreParagraphBlockPreview}  
  ${CoreTableBlockPreview}
  ${CoreSpacerBlockPreview}
  `
