export const query = (
  queryObj: any,
  url: any,
  callback: (arg0: any) => void
) => {
  fetch(`${url}graphql?query=${queryObj}`)
    .then(response => response.json())
    .then(data => {
      callback(data)
    })
    .catch(error => {
      console.error(error)
    })
}

export const queryGraphql = (
  queryObj: any,
  token: string,
  endpoint: string | undefined,
  callback: (data: any) => void
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  }

  const body = JSON.stringify(queryObj)

  const url =
    process.env.NODE_ENV === "development" && endpoint?.includes("localhost")
      ? endpoint
        ? `${endpoint.replace(/^https?:\/\//i, `http://`)}wp/graphql`
        : process.env.GATSBY_ADMIN_URL
      : `${endpoint}wp/graphql`

  fetch(`${url}`, {
    method: "POST",
    headers,
    body,
    credentials: "include",
    mode: "cors",
    cache: "no-cache",
  })
    .then(response => response.json())
    .then((data: any) => {
      callback(data)
    })
    .catch((error: any) => {
      console.error(error)
    })
}
