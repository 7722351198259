import React, { useEffect, useReducer } from "react"
import Layout from "../components/Layout"
import { queryGraphql } from "~/services/api"
import { buildBlocks } from "~/services/builder"
import { PreviewBlocks } from "../templates/fragment"

type State = {
  loading: boolean
  title: string
  blocks: any[]
  meta: any
  error: boolean
  errorMessage: string
}

type Action =
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_TITLE"; payload: string }
  | { type: "SET_BLOCKS"; payload: any[] }
  | { type: "SET_META"; payload: any }
  | { type: "SET_ERROR"; payload: boolean }
  | { type: "SET_ERROR_MESSAGE"; payload: string }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload }
    case "SET_TITLE":
      return { ...state, title: action.payload }
    case "SET_BLOCKS":
      return { ...state, blocks: action.payload }
    case "SET_META":
      return { ...state, meta: action.payload }
    case "SET_ERROR":
      return { ...state, error: action.payload }
    case "SET_ERROR_MESSAGE":
      return { ...state, errorMessage: action.payload }
    default:
      return state
  }
}

const initialState: State = {
  loading: true,
  title: "",
  blocks: [],
  meta: {},
  error: false,
  errorMessage: "",
}

export default function Preview() {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (typeof window !== "undefined") {
      const parsedUrl = new URL(window.location.href)
      const id = parsedUrl.searchParams.get("id")
      const token = parsedUrl.searchParams.get("token")
      const endpoint = parsedUrl.searchParams.get("endpoint")

      const blocks = PreviewBlocks

      if (id === null || token === null || endpoint === null) {
        dispatch({ type: "SET_ERROR", payload: true })
        dispatch({
          type: "SET_ERROR_MESSAGE",
          payload: "Missing parameters in URL",
        })
        dispatch({ type: "SET_LOADING", payload: false })
        return
      }

      const queryObject = query(id, blocks)
      createContent(queryObject, token, endpoint)
    }
  }, [])

  const createContent = (
    queryObject: { query: string },
    token: string,
    endpoint: string
  ) => {
    queryGraphql(queryObject, token, endpoint, response => {
      const post = response?.data ? response.data.post : null

      if (post === null || response.errors) {
        console.log(response.errors[0])
        console.error(response.errors[0].debugMessage)
        dispatch({ type: "SET_ERROR", payload: true })
        dispatch({
          type: "SET_ERROR_MESSAGE",
          payload: response.errors[0].message,
        })
      } else {
        const blocks = buildBlocks(post.blocks)

        dispatch({ type: "SET_TITLE", payload: post.title })
        dispatch({ type: "SET_BLOCKS", payload: blocks })
        dispatch({ type: "SET_META", payload: post.Meta })
      }
      dispatch({ type: "SET_LOADING", payload: false })
    })
  }

  // TODO: match styles to page
  return (
    <Layout
      meta={state.meta}
      title={`Preview: ${state.title}`}
      path="/preview-page/"
    >
      <div className="my-16 mx-auto w-[90%] max-w-screen-lg">
        <h1 className="px-3 text-h2">
          {state.title ? `Preview: ${state.title}` : `Preview`}
        </h1>
        {state.loading ? (
          <p className="my-8 text-h4">
            Loading{" "}
            <span className="inline-flex tracking-widest delay-200">
              <span className="block animate-bounce [animation-delay:300ms]">
                .
              </span>
              <span className="block animate-bounce [animation-delay:200ms]">
                .
              </span>
              <span className="block animate-bounce [animation-delay:100ms]">
                .
              </span>
            </span>
          </p>
        ) : null}
      </div>
      <div className="mt-8">
        {state.blocks &&
          state.blocks.map(block => {
            return block
          })}
      </div>
      {state.error ? (
        <p className={"error-message"}>{state.errorMessage}</p>
      ) : null}
    </Layout>
  )
}

const query = (id: string, blocks: string) => {
  const q = `{
    post(id: "${id}", idType: DATABASE_ID) {
      title
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage{
          uri
        }                            
      }                       
      ... on BlockEditorContentNode {
        blocks {
          ... on CoreBlock {
            reusableBlock {
              __typename
              ... on ReusableBlock {
                id
                blocks {
                  ${blocks}
                  innerBlocks {
                    name
                    ${blocks}
                    innerBlocks {
                      name
                      ${blocks}
                      innerBlocks {
                        name
                        ${blocks}                            
                      }                                     
                    }                          
                  }                                         
                }
              }
            }
          }
          ${blocks}
          innerBlocks {
            name
            ${blocks}
            innerBlocks {
              name
              ${blocks}
              innerBlocks {
                name
                ${blocks}                            
              }                                     
            }                          
          } 
        }                     
      }  
    },
  }`

  return {
    query: q,
  }
}
