import { graphql } from "gatsby"

export const AcfBackgroundContainerBlockFragment = graphql`
  fragment AcfBackgroundContainerBlock on WpBlock {
    ... on WpAcfBackgroundContainerBlock {
      name
      attributes {
        className
      }
      blockBackgroundContent {
        backgroundColour
      }
    }
  }
`

// This is the string version of this that is used within previews and without WP prefixes
export const AcfBackgroundContainerBlockPreview = `
  ... on AcfBackgroundContainerBlock {
      name
      attributes {
          className
      }
      blockBackgroundContent {
        backgroundColour
      }
  }
`
